
import { mapState, mapGetters } from 'vuex'
import baseLogic from '~/mixins/base-logic'
import cms from '~/mixins/common/cms'
export default {
    name: 'Theme3',
    mixins: [baseLogic, cms],
    data() {
        return {
            isMobileScreen: false,
            loginModal: false,
            registerModal: false,
            showHoldBalance: false,
            displayGetBonusModal: false,
            displayGetBonusDetail: {},
            displayWithdrawalSocket: false,
            withdrawalStatus: true,
        }
    },
    head() {
        return {
            link: [
                {
                    rel: 'icon',
                    type: 'image/x-png',
                    href: `${
                        this.getLocaleLang(this.faviconIcon.image_path)
                            ? this.cmsImageLink({
                                  path: this.getLocaleLang(
                                      this.faviconIcon.image_path
                                  ),
                                  code: this.faviconIcon?.component_code,
                              })
                            : ''
                    }`,
                },
            ],
        }
    },
    computed: {
        ...mapGetters({
            faviconIcon: 'cms/faviconIcon',
        }),
        ...mapState({
            isLogin: (state) => state.settings.isLogin,
            displayRegisterModal: (state) =>
                state.themes.settings.displayRegisterModal,
        }),
        navbarName() {
            if (this.isMobileOnly()) {
                return 'Theme4MobileNavBar'
            }
            return 'Theme4DesktopNavBar'
        },
    },
    watch: {
        displayRegisterModal(value) {
            this.registerModal = value
        },
    },
    async mounted() {
        if (this.$cookie?.get('is_change_language')) {
            this.$store.commit('settings/changeLanguageLoading', true)
        }
        if (this.handleCookie('wl_locale')) {
            this.$recaptcha.language = this.handleCookie('wl_locale')
        }
        this.$store.dispatch('cms/cms', {})
        this.$store.dispatch('themes/settings/whiteLabel')
        this.$store.dispatch('settings/supportCountryAndLanguage')
        this.$store.dispatch('themes/settings/getSupportCountry')
        this.$store.dispatch('themes/settings/getExchangeRate')

        // If the website is agent website, display agent floating icons
        this.$store.dispatch('cms/floatingIcons')

        await this.setDefaultLanguages()

        if (this.$cookie.get('token')) {
            this.socket = await this.$nuxtSocket({
                reconnection: false,
                auth: {
                    Authorization: `Bearer ${this.$cookie.get('token')}`,
                },
                transports: ['websocket'],
            })
            this.socket.on('disconnect', (e) => {
                this.socket.connect()
            })

            // Backend team need this callback
            this.socket.on('ws-ping', (payload, callback) => {
                try {
                    // eslint-disable-next-line n/no-callback-literal
                    callback('ykx52280')
                } catch (_) {}
            })

            let reconnectCount = 0
            this.socket.on('connect_error', (e) => {
                //  in case token is expired
                if (typeof e === 'object') {
                    const message = e?.message
                    try {
                        const messageObject = JSON.parse(message)
                        if (messageObject?.code === 401) {
                            this.logout()
                        }
                    } catch (_) {}
                }
                const MAX_RECONNECTION_FAIL_COUNT = 1000
                const TIME_INTERVAL_TO_RECONNECT = 1000

                if (reconnectCount < MAX_RECONNECTION_FAIL_COUNT) {
                    setTimeout(() => {
                        this.socket.connect()
                    }, TIME_INTERVAL_TO_RECONNECT)
                    reconnectCount++
                }
            })

            this.socket.on('playerBalance', (msg) => {
                this.$store.dispatch('player/totalBalanceSocket', msg)
            })
            this.socket.on('revokeWithdrawal', async (msg) => {
                this.withdrawalStatus = false
                this.displayWithdrawalSocket = true
                if (!this.$root?.withdrawExistChange) return
                const result = await this.$store.dispatch(
                    'themes/withdraw/checkExistWithdrawal'
                )
                this.$root.withdrawExistChange(result)
            })
            this.socket.on('successfulWithdrawal', async (msg) => {
                this.withdrawalStatus = true
                this.displayWithdrawalSocket = true
                if (!this.$root?.withdrawExistChange) return
                const result = await this.$store.dispatch(
                    'themes/withdraw/checkExistWithdrawal'
                )
                this.$root.withdrawExistChange(result)
            })
            this.socket.on('unlockWithdrawal', async (msg) => {
                if (!this.$root?.withdrawExistChange) return
                const result = await this.$store.dispatch(
                    'themes/withdraw/checkExistWithdrawal'
                )
                this.$root.withdrawExistChange(result)
            })
            this.socket.on('lockWithdrawal', async (msg) => {
                if (!this.$root?.withdrawExistChange) return
                const result = await this.$store.dispatch(
                    'themes/withdraw/checkExistWithdrawal'
                )
                this.$root.withdrawExistChange(result)
            })
            this.socket.on('onHoldPlayerBalance', (msg) => {
                this.$store.dispatch('player/setHoldBalance', msg.balance)
                this.showHoldBalance = true
            })
            this.socket.on('successfulDeposit', (msg) => {
                this.displayGetBonusDetail = msg
                this.displayGetBonusModal = true
            })

            // Subscribe Store, Socket Only
            this.$store.subscribeAction((mutation, state) => {
                // Confirm Deposit
                if (
                    mutation.type === 'sockets/revokeWithdrawal' ||
                    mutation.type === 'sockets/successfulWithdrawal' ||
                    mutation.type === 'sockets/revokeDepositByAdmin' ||
                    mutation.type === 'sockets/successfulDeposit'
                ) {
                    if (!this.$root?.isTransactionHistoryPage) return
                    this.$store.dispatch('themes/transaction/getTransactions', {
                        sort: 'created_at|desc',
                        limit: 10,
                        page: 1,
                        language: this.handleCookie('wl_locale'),
                        start_date: '2022-01-01 00:00:00',
                        end_date: this.$dayjs().format('YYYY-MM-DD HH:mm:ss'),
                    })
                }
            })

            await this.$store.dispatch(
                'themes/player-bank/getBankPlayerAccount'
            )
        }
        await this.$store.commit('settings/changeLanguageLoading', false)
        this.$cookie.remove('is_change_language')
    },
    methods: {
        onResize() {
            if (process.browser) {
                const MOBILE_SCREEN_WIDTH = 1300
                this.isMobileScreen = window.innerWidth < MOBILE_SCREEN_WIDTH
                this.$store.commit(
                    'themes/settings/setIsMobile',
                    this.isMobileScreen
                )
                return null
            }
            this.isMobileScreen = false
            this.$store.commit(
                'themes/settings/setIsMobile',
                this.isMobileScreen
            )
        },
        onShowLoginModal() {
            this.loginModal = true
        },
        onShowRegisterModal() {
            this.registerModal = true
        },
        closeHoldBalanceDialog() {
            this.showHoldBalance = false
        },
    },
}
